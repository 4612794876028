<template>
<div>
  <van-cell-group title="通用">
    <van-cell title="领用出库" is-link size="large" icon="after-sale" to="/mat/bill/new?tp=1" v-if="whale.user.validate('/mat/bill/1')"/>
    <van-cell title="采购入库" is-link size="large" icon="cart-o" to="/mat/bill/new?tp=2" v-if="whale.user.validate('/mat/bill/2')"/>
    <van-cell title="记录查询" is-link size="large" icon="records" to="/mat/bill" v-if="whale.user.validate('/mat/bill/1')"/>
  </van-cell-group>
  <van-cell-group title="库存物品">
    <van-cell title="库存信息" is-link size="large" icon="balance-o" to="/mat/stock" v-if="whale.user.validate('/mat/stock')"/>
    <van-cell title="新增物品" is-link size="large" icon="edit" to="/mat/stock/new" v-if="whale.user.validate('/mat/stock/new')"/>
  </van-cell-group>
</div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped></style>
